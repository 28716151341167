// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modal_wrap__HD79\\+ .modal-dialog {
  max-width: 700px;
  width: 100%;
}
.styles_modal_wrap__HD79\\+ .modal-dialog .modal-content {
  border: 1px solid #c9daff;
  border-radius: 8px;
}
.styles_modal_wrap__HD79\\+ .styles_cross__BSBfo {
  cursor: pointer;
  background-color: #ebf0ff;
  position: absolute;
  top: 14px;
  right: 18px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_textVerticalCenter__vMehl {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27vh;
  flex-direction: column;
}
.styles_textVerticalCenter__vMehl .styles_text__RaKEC {
  color: #757575;
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 27px;
}`, "",{"version":3,"sources":["webpack://./src/components/checkout/styles.module.scss"],"names":[],"mappings":"AAEI;EACE,gBAAA;EACA,WAAA;AADN;AAEM;EACE,yBAAA;EACA,kBAAA;AAAR;AAIE;EACE,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,sBAAA;AAHF;AAIE;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAFJ","sourcesContent":[".modal_wrap {\n  :global {\n    .modal-dialog {\n      max-width: 700px;\n      width: 100%;\n      .modal-content {\n        border: 1px solid #c9daff;\n        border-radius: 8px;\n      }\n    }\n  }\n  .cross {\n    cursor: pointer;\n    background-color: #ebf0ff;\n    position: absolute;\n    top: 14px;\n    right: 18px;\n    border-radius: 50%;\n    width: 25px;\n    height: 25px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n\n.textVerticalCenter {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 27vh;\n  flex-direction: column;\n  .text {\n    color: #757575;\n    font-size: 20px;\n    line-height: 18px;\n    margin-bottom: 27px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_wrap": `styles_modal_wrap__HD79+`,
	"cross": `styles_cross__BSBfo`,
	"textVerticalCenter": `styles_textVerticalCenter__vMehl`,
	"text": `styles_text__RaKEC`
};
export default ___CSS_LOADER_EXPORT___;
