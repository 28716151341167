import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { ENV } from "../../../config/config";
import styles from "./styles.module.scss";
import { Modal, ModalContent } from "./Modal";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    partialVisibilityGutter: 120,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 80,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
    partialVisibilityGutter: 60,
  },
  mobile: {
    breakpoint: { max: 668, min: 0 },
    items: 1,
  },
};

export default function CaravanLocationImages({ caravanImagesList }) {
  const [caravanImages, setCaravanImages] = useState([]);
  const [isOpen, setIsopen] = useState(false);
  const [caravanImageUrl, setCaravanImageUrl] = useState(null);
  const showModal = () => {
    
    setIsopen((prev) => !prev);
  }

  useEffect(() => {
    setCaravanImages(caravanImagesList);
  }, [caravanImages]);

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-12 col-lg-12" style={{textAlign: "center"}}>
        <h3 className="mt-3 mb-3" style={{fontSize: "30px", fontWeight: "600", lineHeight: "36px"}}>Caravan Location</h3>
        <span style={{fontSize: "24px", fontWeight: "400", lineHeight: "29px"}}>Explore the beautiful caravan options available at your selected location. Browse through the images below to see the features and amenities offered.
        </span>
      </div>
      <div className="col-12 col-md-12 col-lg-12">
        <Carousel
          className={styles.carouselStyles}
          responsive={responsive}
          slidesToSlide={1}
          autoPlaySpeed={3000}
          autoPlay
          swipeable
          infinite
          minimumTouchDrag={80}
          draggable
          arrows
          partialVisbile
        >
          {caravanImagesList.length > 0 &&
            caravanImagesList.map((caravan, i) => (
              <Modal onOpen={showModal}>
                <div key={i} style={{ marginInline: "0.3rem", height: 200, cursor: "pointer" }} onClick={() => setCaravanImageUrl(`${ENV.fileBaseUrl}/${caravan.fileName}`)}>
                  <img
                    style={{ objectFit: "contain" }}
                    src={`${ENV.fileBaseUrl}/${caravan.fileName}`}
                    alt="caravan img"
                    width={"100%"}
                  // height={200}
                  />
                </div>
              </Modal>
              
            ))}
        </Carousel>
      </div>
      <div>
      {/* {caravanImageUrl && <Modal onOpen={showModal}>
        <div className="holder">
          <img src={caravanImageUrl} alt="" />
        </div>
      </Modal>} */}
      {isOpen && (
        <ModalContent onClose={() => setIsopen(false)}>
          <img style={{objectFit: "contain", width: "100%", height: "100%"}} src={caravanImageUrl} alt="" />
        </ModalContent>
      )}
      </div>
      
    </div>
  );
}
