// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/events-art.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__sYvAD {
  margin: 100px auto;
  position: relative;
}
.styles_wrapper__sYvAD:after {
  content: "";
  position: absolute;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  left: 0;
  top: 400px;
  z-index: -1;
  height: 265px;
  width: 214px;
  object-fit: contain;
  object-position: center;
}
@media (max-width: 992px) {
  .styles_wrapper__sYvAD:after {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .styles_wrapper__sYvAD {
    margin: 50px auto;
  }
}
.styles_wrapper__sYvAD .styles_heading__RhchY {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 56px;
  text-align: left;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 0;
}
@media only screen and (max-width: 992px) {
  .styles_wrapper__sYvAD .styles_heading__RhchY {
    font-size: 24px;
    margin-bottom: -15px;
  }
}
@media only screen and (max-width: 992px) {
  .styles_wrapper__sYvAD .styles_heading__RhchY {
    font-size: 20px;
    margin-bottom: -35px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/home/trending/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;AACF;AAAE;EACE,WAAA;EACA,kBAAA;EACA,yDAAA;EACA,OAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AAEJ;AADI;EAXF;IAYI,aAAA;EAIJ;AACF;AADE;EAnBF;IAoBI,iBAAA;EAIF;AACF;AAHE;EAEE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,0BAAA;EACA,cAAA;EACA,gBAAA;AAIJ;AAHI;EAVF;IAWI,eAAA;IACA,oBAAA;EAMJ;AACF;AALI;EAdF;IAeI,eAAA;IACA,oBAAA;EAQJ;AACF","sourcesContent":[".wrapper {\n  margin: 100px auto;\n  position: relative;\n  &:after {\n    content: \"\";\n    position: absolute;\n    background-image: url(\"../../../assets/events-art.svg\");\n    left: 0;\n    top: 400px;\n    z-index: -1;\n    height: 265px;\n    width: 214px;\n    object-fit: contain;\n    object-position: center;\n    @media (max-width: 992px) {\n      display: none;\n    }\n  }\n\n  @media only screen and (max-width: 992px) {\n    margin: 50px auto;\n  }\n  .heading {\n    // font-family: \"Bakbak One\";\n    font-style: normal;\n    font-weight: 400;\n    font-size: 36px;\n    line-height: 56px;\n    text-align: left;\n    text-transform: capitalize;\n    color: #333333;\n    margin-bottom: 0;\n    @media only screen and (max-width: 992px) {\n      font-size: 24px;\n      margin-bottom: -15px;\n    }\n    @media only screen and (max-width: 992px) {\n      font-size: 20px;\n      margin-bottom: -35px;\n    }\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__sYvAD`,
	"heading": `styles_heading__RhchY`
};
export default ___CSS_LOADER_EXPORT___;
