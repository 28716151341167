import React, { useState, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import contactUsImg from "../../../assets/contact-us-image.png";
import footerVideo from "../../../assets/footer-video.mp4";
import styles from "./styles.module.scss";
import { handleRequest } from "../../../helpers/helpers";
import GoogleMap from "../../common/googleMap";
import { IntlContext } from "../../../context/Internationalization";
import CaravanLocationImages from "./caravanLocationImages";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const ContactUs = ({page}) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    description: ""
  });
  const [loading, setLoading] = useState(false);
  const [caravanImagesList, setCaravanImagesList] = useState([])
  const context = useContext(IntlContext);
  const [playing, setPlaying] = useState(true);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    getAllLocattions();
  },[])

  useEffect(() => {
    console.log("locations", locations[0]?._id)
    setSelectedLocation(locations[0]?._id);
  }, [locations]);

  useEffect(() => {
    if (selectedLocation) {
      getCaravanLocationImages(selectedLocation);
    }
  }, [selectedLocation]);

  const getAllLocattions = async () => {
    try {
      const res = await handleRequest("get", "/map/location");
      if (res?.data?.data) {
        const locationsData = res?.data?.data;
        setLocations(locationsData);
      } else {
      }
    } catch (err) {
     console.log("🚀 ~ getAllLocattions ~ err:", err)
     }
  }

  const getCaravanLocationImages = async () => {
    try {
      const res = await handleRequest("get", `/caravan-location-images/${selectedLocation}`);
      if (res.status === 200) {
        setCaravanImagesList(res.data)
      }
    } catch (err) {
      console.log(err, "err==>");
    }
  };

  const hanldePlayPlause = () => {
    var myVideo = document.getElementById("video1");
    if (myVideo.paused) {
      myVideo.play(); 
      setPlaying(false);
    }
    else {
      myVideo.pause();
      setPlaying(true);
    }
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const res = await handleRequest("post", "/support/contactUs", values);
      if (res.data.success) {
        setLoading(false);
        setValues({ name: "", email: "", description: "" });
        toast.success("Response received successfully");
      } else {
        setLoading(false);

        toast.error(res.data.message);
      }
    } catch (err) {
      console.log(err, "err");
      setLoading(false);
    }
  };
  const { name, email, description } = values;
  return (
    <>
      <div className={styles.container}>
        <img src={contactUsImg} alt="Snow" />
        <div className={styles.overlay}>
        </div>
        <span className={styles.btn}>CONTACT US</span>
      </div>
      <div className={styles.wrapper} dir={context.state.locale === "sa" ? "rtl" : "ltr"}>

        <div className={styles.lts}>
          <div className={`${page === "gift" ? "" : "container"} ${styles.box}`}>
            <div className="row">
              <div className="col-lg-6">
                <form onSubmit={handleSubmit}>
                  <div className={styles.contact_form}>
                    <div className={styles.heading}><FormattedMessage id="Let’s Connect" /> </div>
                    <span className={styles.reachText}>Reach out to us with any questions, feedback, or inquiries. We're here to connect with our community and assist you in every way possible.</span>
                    <input
                      type="text"
                      placeholder={context.state.locale === "sa" ? "اسم" : "Name"}
                      name="name"
                      minLength={3}
                      maxLength={40}
                      value={name}
                      required
                      className={context.state.locale === "sa" && "text-inherit"}
                      onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                      }
                    />
                    <input
                      type="email"
                      placeholder={context.state.locale === "sa" ? "بريد إلكتروني" : "Email"}
                      maxLength={60}
                      name="email"
                      value={email}
                      required
                      className={context.state.locale === "sa" && "text-inherit"}
                      onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                      }
                    />
                    <textarea
                      rows={5}
                      placeholder={context.state.locale === "sa" ? "مالذي تريد قوله؟" : "what you wanna say?"}
                      name="description"
                      minLength={3}
                      maxLength={250}
                      value={description}
                      className={context.state.locale === "sa" && "text-inherit"}
                      required
                      onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                      }
                    ></textarea>
                    <button type="submit" disabled={loading}>
                      {loading ? <FormattedMessage id="Loading" /> : <FormattedMessage id="Submit" />}
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-6 justify-content-center mt-lg-0 mt-50">
                <div className={styles.headingMap}><FormattedMessage id="FIND DRB CARAVAN" /> </div>

                <div className="justify-content-center" style={{ marginTop: -10 }}>
                  <div style={{ height: "53vh" }}><GoogleMap location={{ lat: 31.503673, lng: 74.331678 }} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} /></div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <video width="100%" autoPlay loop muted>
          <source src={footerVideo} type="video/webm" />
        </video>
      </div> */}
      <div>
        <CaravanLocationImages caravanImagesList={caravanImagesList} />
      </div>
      <div className={styles.containerVideo}>
        <video id="video1" loop>
          <source src={footerVideo} type="video/mp4" />
        </video>
        <div onClick={hanldePlayPlause} className={styles.playBtn}>
          {playing ? <PlayArrowIcon className={styles.playIcon} /> : 
          <PauseIcon className={styles.playIcon} />}
        </div>
      </div>
    </>
  );
};

export default ContactUs;
