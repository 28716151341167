import char1 from "./char1.gif";
import char2 from "./char2.gif";
import char3 from "./char3.gif";
import char4 from "./char4.gif";
import char5 from "./char5.gif";
import char6 from "./char6.gif";
import char7 from "./char7.gif";
import char8 from "./char8.gif";
import char9 from "./char9.gif";
import char10 from "./char10.gif";
import char11 from "./char11.gif";

const animation = {
  char1,
  char2,
  char3,
  char4,
  char5,
  char6,
  char7,
  char8,
  char9,
  char10,
  char11,
};
export default animation;
