import React, { useEffect, useState, useRef, useMemo } from "react";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import L from "leaflet";
import { handleRequest } from "../../../helpers/helpers";
import "leaflet/dist/leaflet.css";

const GoogleMap = ({ selectedLocation, setSelectedLocation }) => {
  const [storeLocation, setStoreLocation] = useState({ lat: "31.503673", lng: "74.331678" });
  const [locationsData, setLocationsData] = useState([]);
  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = async () => {
    try {
      const res = await handleRequest("get", "/map/location");
      if (res.data.success) {
        const data = res.data.data;        
        // lat: 31.503673, lng: 74.331678 
        setStoreLocation({ lat: "31.503673", lng: "74.331678" });
        setLocationsData(data);
      }
    } catch (err) {
      console.log(err, "err==>");
    }
  };

  // Define the custom marker icon
  const markerIcon = new L.Icon({
    iconUrl:
      "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
    iconSize: [32, 32],
    iconAnchor: [16, 32]
  });

  return (
    <>
      {locationsData && (
        <MapContainer
          center={storeLocation}
          zoom={14}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {locationsData.map((location, index) => (
            <Marker
              key={index} 
              position={{ lat: location.lat, lng: location.lng }}
              icon={markerIcon}
              eventHandlers={{
                click: (e) => {
                  setSelectedLocation(location?._id);
                },
              }}
            >
              <Popup>
                <div style={{cursor: "pointer"}} onClick={() => {
                  window.open(`https://www.google.com/maps?q=${location.lat},${location.lng}`, '_blank');
                }}>
                {location.title}
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      )}

    </>
  );
};

export default GoogleMap;
