// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__3jPU3 .styles_lts__M0Atf {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (max-width: 992px) {
  .styles_wrapper__3jPU3 .styles_lts__M0Atf {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ordersHistory/main/styles.module.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,qBAAA;AAAJ;AACI;EAHF;IAII,iBAAA;IACA,oBAAA;IACA,WAAA;EAEJ;AACF","sourcesContent":[".wrapper {\n  .lts {\n    padding-top: 100px;\n    padding-bottom: 100px;\n    @media (max-width: 992px) {\n      padding-top: 50px;\n      padding-bottom: 50px;\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__3jPU3`,
	"lts": `styles_lts__M0Atf`
};
export default ___CSS_LOADER_EXPORT___;
